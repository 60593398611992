const blacklist = [
    'MA', // Morocco
    'DZ', // Algeria
    'TN', // Tunisia
    // 'CH', // Switzerland (for testing)
];

const getGeo = async () => {
    const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const data = await response.text();
    const ip = data.match(/ip=(.*)/)[1];
    const country = data.match(/loc=(.*)/)[1];
    return { ip, country };
};

const getCountryCode = async () => {
    let country = sessionStorage.getItem('countryCode');
    if (!country) {
        const geo = await getGeo();
        country = geo.country;
        sessionStorage.setItem('countryCode', country);
    }
    return country;
};

export const isBlacklistedCountry = async () => {
    const country = await getCountryCode();
    return blacklist.includes(country);
};
